"use client"
import axios, { AxiosResponse } from "axios";
import { Customer, CustomerListDto, CustomerSearchRequest, CustomerBookingsList, CustomerInfos, CustomerBookingSearchRequest, CreateDealCustomerRequest, CustomerUnit } from "../../models/dto/customers/customer.dto";
import { authHeader } from "../../helpers/authHeader/auth-header";
import { ContactInfoModel } from "../../models/dto/settings/contactInfo.model";
import { CustomerRegisterModel } from "../../models/dto/auth/customerRegister.dto";

const API_URL = `${process.env.REACT_APP_BASE_API_URL}/customer`;
const getCustomers = (customerSearchRequest: CustomerSearchRequest, forCompany?: boolean): Promise<CustomerListDto> => {
    return axios.get<any, AxiosResponse<CustomerListDto>>(forCompany ?  API_URL + "/find-for-company" : API_URL + `/find`,
        { params: customerSearchRequest, headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}

const getCustomer = (id: number): Promise<Customer> => {
    return axios.get<any, AxiosResponse<Customer>>(`${API_URL}/${id}`,
        { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const getSelfProfile = (): Promise<Customer> => {
    return axios.get<any, AxiosResponse<Customer>>(`${API_URL}`,
        { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const updateSelfProfile = (customerProfile: Customer): Promise<string> => {
    return axios.put<Customer, AxiosResponse<string>>(`${API_URL}/update-profile`, customerProfile,
        { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const updateCustomer = (customer: Customer): Promise<Customer> => {
    return axios
        .put<Customer, AxiosResponse<Customer>>(`${API_URL}`, customer, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}

const getCustomerBookings = (customerBookingSearchRequest: CustomerBookingSearchRequest): Promise<CustomerBookingsList> => {
    return axios.get<any, AxiosResponse<CustomerBookingsList>>(`${API_URL}/customerAndBookings/${customerBookingSearchRequest.id}`, { params: customerBookingSearchRequest, headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateCustomerInfos = (customerinfos: CustomerInfos): Promise<CustomerInfos> => {
    return axios
        .put<Customer, AxiosResponse<CustomerInfos>>(`${API_URL}`, customerinfos, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const createDealCustomer = (createDealCustomerRequest: CreateDealCustomerRequest): Promise<CustomerRegisterModel> => {
    return axios
        .post<CreateDealCustomerRequest, AxiosResponse<CustomerRegisterModel>>(`${process.env.REACT_APP_BASE_API_URL}/dealcustomer/create`, createDealCustomerRequest, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const getDealCustomerUnits = (organizationId?: number): Promise<CustomerUnit[]> => {
    const conf = organizationId ? { headers: authHeader(), params: {organizationId} } : {headers: authHeader()}
    return axios.get<any, AxiosResponse<CustomerUnit[]>>(`${process.env.REACT_APP_BASE_API_URL}/dealcustomer/units-for-purchase`, conf)
        .then((response) => {
            return response.data;
        });
}

const getAllCustomerUnits = (isActive:boolean): Promise<CustomerUnit[]> => {
    return axios.get<any, AxiosResponse<CustomerUnit[]>>(isActive?`${process.env.REACT_APP_BASE_API_URL}/customerunit/all-active`:`${process.env.REACT_APP_BASE_API_URL}/customerunit/all`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}

const getContactInfo = (): Promise<ContactInfoModel> => {
    var promise = new Promise<ContactInfoModel>((resolve, reject) => {
        var contactInfoJson = localStorage.getItem("lastContactInfo");
        if (contactInfoJson)
            resolve(JSON.parse(contactInfoJson) as ContactInfoModel);
        else
            getCurrentCustomer().then((res) => {
                const contactInfo = { ...res, email: res.email, phoneNumber: res.phone, contactInfoType: res.customerType, contactName: res.name };
                localStorage.setItem("lastContactInfo", JSON.stringify(contactInfo));
                resolve(contactInfo);
            }, (error) => {
                reject(error);
            });
    });
    return promise;
}

const getCurrentCustomer = (): Promise<Customer> => {
    return axios
        .get<Customer, AxiosResponse<Customer>>(`${API_URL}`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const deleteAccount = (customerId?: number): Promise<string> => {
    return axios
        .get<number, AxiosResponse<string>>(customerId ? `${process.env.REACT_APP_BASE_API_URL}/user/erase/${customerId}` : `${process.env.REACT_APP_BASE_API_URL}/user/erase`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}

const customerService = {
    getCustomers,
    getCustomer,
    getContactInfo,
    getCurrentCustomer,
    updateCustomer,
    getCustomerBookings,
    updateCustomerInfos,
    getSelfProfile,
    updateSelfProfile,
    deleteAccount,
    createDealCustomer,
    getDealCustomerUnits,
    getAllCustomerUnits
};

export default customerService;

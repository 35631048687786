import { Suspense, lazy, useEffect, useState } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import GuardedRoute from "./components/guardedRoute/GuardedRoute";
import { appRoutes } from "../routes";
import { RoutesContext } from "./contexts/routes.context";
import moment from "moment";
import "moment/locale/sv";
import "moment/locale/en-au";
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";
import { UserRole } from "./models/dto/users/user.dto";
import useBrowserBackStack from "./hooks/browserBackStack";
import { BrowserHistoryContext } from "./contexts/browserHistory.context";

const DashboardComponent = lazy(() => import("./dashboard/DashboardComponent"));
const PagesComponent = lazy(() => import("./pages/PagesComponent"));

const AppComponent = () => {
    const [routes, setRoutes] = useState(appRoutes);

    const { t, i18n } = useTranslation();
    const backStack = useBrowserBackStack();

    const baseRouteUrl = "/:locale(en|sv)?";
    const { locale } = useParams<{ locale: string }>();
    if (!locale) {
        window.location.href = window.location.pathname == "/undefined" ? "/sv" : "/sv" + window.location.pathname;
    }
    moment.locale(locale == "en" ? "en-au" : locale);
    var faq_slug = 'vanliga-fragor-2';
    var about_slug = 'om-bookabus-se';
    var contact_slug = 'kontakta-oss';
    if (i18n.language == 'en'){
        faq_slug = 'faq';
        about_slug = 'about';
        contact_slug = 'contact-us';
    }
    const updateRoutesByLanguage = (language: string, defaultRoutes: any) => {
        let newRoutes: any = {}
        Object.keys(routes).forEach((key) => {
            if (typeof routes[key] == "object") {
                newRoutes[key] = updateRouteProperties(routes[key], language);
            }
            else {
                newRoutes[key] = "/" + language + routes[key];
            }
        });
        setRoutes(newRoutes);
    }
    const updateRouteProperties = (routeObject: any, language: string) => {
        let properties = Object.keys(routeObject);
        let newRoutes: any = {};
        properties.forEach((key) => {
            if (typeof routeObject[key] == "object") {
                newRoutes[key] = updateRouteProperties(routeObject[key], language);
            }
            else if (routeObject[key]) {
                const pathParam = routeObject[key].toString().split("/").find((c: string) => c.startsWith(":"));
                if (pathParam) {
                    newRoutes[key] = "/" + language + routeObject[key].toString().replace(pathParam, "");
                }
                else {
                    newRoutes[key] = "/" + language + routeObject[key];
                }
            }
        });
        return newRoutes;
    }


    

    useEffect(() => {
        i18n.changeLanguage(locale);
        updateRoutesByLanguage(locale, appRoutes);
    }, [])
    // @ts-ignore
    return <>

        {<RoutesContext.Provider value={{ routes: routes, setLanguage: (language: string) => { updateRoutesByLanguage(language, appRoutes) } }}>
            <BrowserHistoryContext.Provider value={{ backStack: backStack as any }}>

                <Suspense fallback={<div></div>}>

                    <Switch>

                        <GuardedRoute 
                            path={baseRouteUrl + appRoutes.dashboard.main.path}>
                            <DashboardComponent />
                        </GuardedRoute>
                        <GuardedRoute
                            notAllowedRoles={[
                                { name: UserRole.SystemAdmin, redirectRoute: routes.dashboard.admin.main.path },
                                { name: UserRole.SystemOwner, redirectRoute: routes.dashboard.admin.main.path },
                                { name: UserRole.CompanyAdmin, redirectRoute: routes.dashboard.company.main.path },
                                { name: UserRole.DealCustomerFinance, redirectRoute: routes.dashboard.dealOrg.main.path }
                            ]}
                            noAuthNeeded={true}
                            path={baseRouteUrl + appRoutes.main}>
                            <PagesComponent />
                        </GuardedRoute>

                    </Switch>
                </Suspense>
            </BrowserHistoryContext.Provider>

        </RoutesContext.Provider>}

    </>

}
export default AppComponent;
if (typeof window !== "undefined")
    window.dataLayer = window.dataLayer || [];

const push = (object: any): void => {
    if (typeof window !== "undefined")
        window.dataLayer.push(object);
};

const dataLayerHelper = {
    push
};
export default dataLayerHelper;